import { css } from '@emotion/react';
// components
import Layout from 'components/Layout';
import Font from 'components/atoms/Font';
import Spacing from 'components/atoms/Spacing';
// styles
import { media } from 'styles/cryptodartTheme';

const Greeting = () => {
  return (
    <div
      css={css`
        background: url('/images/about/greeting_bg.png');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center top 126px;
        ${media.lg} {
          background-size: 100%;
        }
      `}
    >
      <Layout
        css={css`
          padding: 160px 24px 80px;
          ${media.sm} {
            padding: 188px 24px 80px;
          }
          ${media.md} {
            padding: 230px 24px 80px;
          }
          ${media.lg} {
            max-width: 794px;
            padding: 160px 0;
          }
        `}
      >
        <div
          css={css`
            display: flex;
            flex-direction: column;
          `}
        >
          <Font type="Subtitle2">소개</Font>
          <div
            css={css`
              display: flex;
              justify-content: space-between;
              align-items: flex-end;
            `}
          >
            <Font type="Heading2">CEO 인사말</Font>
            <img src="/images/about/greeting_logo.png" width={'85px'} height={'40px'} />
          </div>
        </div>
        <Spacing size={44} />
        <div
          css={css`
            display: flex;
            gap: 24px;
            flex-direction: column;
          `}
        >
          <Font type="Body1">
            안녕하십니까, <br />
            디지털자산 신용 평가 기업 크립토다트입니다.
          </Font>
          <Font type="Body1">
            크립토다트의 미션은 데이터와 기술을 활용하여 건전하고 투명한 블록체인 생태계를
            만들어나가는 것입니다. 블록체인 기술은 꾸준한 성장을 거듭하여 디지털 혁신의 중심 역할을
            하고 있습니다. 금융 분야에서 시작하여 스마트 계약, 공급망 관리, 헬스케어, 부동산,
            에너지, 예술 등 다양한 산업으로 확장되었습니다. 이에 따라 수많은 디지털 자산이 등장하며,
            2021년 기준으로는 시장 규모가 300조 원을 돌파했습니다.
          </Font>
          <Font type="Body1">
            그러나 디지털자산의 급격한 성장에 비해 투자자 보호 수단은 아직 미비한 상태입니다.
            크립토다트는 고도의 평가 모델을 개발하여 전 세계 투자자가 디지털자산의 경쟁력을 정확하게
            평가하고 이해할 수 있는 시장을 만들고자 합니다.
          </Font>
          <Font type="Body1">
            크립토다트 팀은 디지털 자산 분야의 전문가로 구성되어 있으며, 신뢰할 수 있는 평가 모델을
            구축하기 위해 법무법인 대륙아주, 나이스평가정보, 우리펀드서비스, 코인플러그,
            코드(CODE)와 같은 다양한 기업과 협력하고 있습니다. 이를 통해 디지털자산의 신용, 기술,
            적법성을 정확하게 평가할 것입니다. 크립토다트의 도전과 혁신에 많은 관심 부탁드립니다.
          </Font>
          <Font type="Body1">감사합니다. </Font>
        </div>
      </Layout>
    </div>
  );
};

export default Greeting;
