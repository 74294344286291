import React, { PropsWithChildren } from 'react';
import { css } from '@emotion/react';
// hook
import useDropdownContext from 'hook/useDropdownContext';

const DropDownList = ({ children }: PropsWithChildren) => {
  const { isOpen } = useDropdownContext();

  if (!isOpen) return null;

  return (
    <div
      css={css`
        position: absolute;
        top: 60px;
        z-index: 1;
        width: 100%;
        padding: 16px;
        border-radius: 12px;
        background-color: #000;
        box-shadow:
          rgba(0, 0, 0, 0.2) 0px 12px 28px 0px,
          rgba(0, 0, 0, 0.1) 0px 2px 4px 0px,
          rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
        left: 0;
        transform: translateY(15px);
      `}
    >
      {children}
    </div>
  );
};

export default DropDownList;
