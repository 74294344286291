import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
// components
import Layout from 'components/Layout';
import Button from 'components/atoms/Button';
import Font from 'components/atoms/Font';
import Icon from 'components/atoms/Icon';
import Spacing from 'components/atoms/Spacing';
// style
import { media } from 'styles/cryptodartTheme';

const Section3 = () => {
  return (
    <Layout
      css={css`
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-bottom: 120px;
      `}
    >
      <Img src="/images/intro/section3_bg.png" alt="section3_bg" />
      <Stroke />
      <div
        css={css`
          display: flex;
          flex-direction: column;
          text-align: center;
          gap: 8px;
          padding: 340px 24px 0;
          ${media.sm} {
            padding-top: 470px;
          }
        `}
      >
        <Font type="Subtitle2">업무 문의</Font>
        <div
          css={css`
            display: flex;
            gap: 4px;
            align-items: center;
          `}
        >
          <Icon
            css={css`
              letter-spacing: -1em;
            `}
          >
            Mail Edit ou-lc
          </Icon>
          <Font type="Body1">rating@cryptodart.com</Font>
        </div>
      </div>
      <Spacing size={44} />
      <div
        css={css`
          max-width: 534px;
          display: flex;
          flex-direction: column;
          text-align: center;
          gap: 20px;
          padding: 0 24px;
          ${media.sm} {
            padding: 0;
          }
        `}
      >
        <Font type="Subtitle1">평가 상담이 필요하신가요?</Font>
        <Font type="Heading2">신청 후 3 영업일 이내에 회신드립니다.</Font>
      </div>
      <Spacing size={44} />
      <Link to="/request">
        <Button>상담 신청</Button>
      </Link>
    </Layout>
  );
};

const Stroke = styled.div`
  position: absolute;
  top: 0;
  width: 2px;
  height: 370px;
  background: linear-gradient(#1b46db, #1b46db6b) !important;
  z-index: -2;
  transform: translateX(-50%);
`;

const Img = styled.img`
  position: absolute;
  top: 120px;
  width: 100%;
  z-index: -2;
  height: auto;
  top: 370px;
  ${media.sm} {
    height: 1002px;
  }
`;
export default Section3;
