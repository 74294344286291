import React, { PropsWithChildren } from 'react';
import { css } from '@emotion/react';
// component
import Font from 'components/atoms/Font';
// hook
import useDropdownContext from 'hook/useDropdownContext';

const DropDownItem = ({
  value,
  disabled,
  children,
}: PropsWithChildren<{ value: string; disabled?: boolean }>) => {
  const { handleSelctAndClose, selected } = useDropdownContext();

  const isSelected = value === selected;
  return (
    <Font
      type="Body1"
      onClick={() => handleSelctAndClose(value)}
      css={css`
        display: block;
        padding: 12px 16px;
        cursor: ${isSelected || disabled ? 'not-allowed' : 'pointer'};
        border-radius: 16px;
        transition: all 0.2s;
        background-color: ${isSelected ? '#1B46DB !important' : 'transparent'};
        color: ${isSelected ? '#FFF !important' : 'rgba(255, 255, 255, 0.68)'};

        &:hover {
          color: #fff;
          background-color: #191919;
        }
      `}
    >
      {children}
    </Font>
  );
};
export default DropDownItem;
