import { TextareaHTMLAttributes, useEffect, useRef, useState } from 'react';
import { css as emotionCss } from '@emotion/react';
import styled from '@emotion/styled';
// component
import Font from './Font';
import Spacing from './Spacing';
// style
import { media } from 'styles/cryptodartTheme';

const errorColor = '#D6535B';

const TextFields = ({ error, errorText, css, maxLength, ...rest }: InputProps) => {
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);

  /**
   * @description textarea 자동 높이 조절 핸들러
   */
  const autoResizeTextarea = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = 'auto';
      const height = textarea.scrollHeight;
      textarea.style.height = `${height + 1}px`;
    }
  };

  useEffect(() => {
    autoResizeTextarea();
  }, [rest.value]);

  return (
    <div>
      <StyledTextArea
        ref={textareaRef}
        css={css}
        error={error}
        rows={1}
        maxLength={maxLength || 500}
        {...rest}
      />
      <Spacing size={8} />
      <ErrorRow>
        <Font
          css={emotionCss`
            font-size: 16px;
            letter-spacing: 0.16px;
          `}
          type="Caption"
          textColor={errorColor}
        >
          {error && <>{errorText}</>}
        </Font>
        {maxLength && (
          <Font type="Caption" textColor={error ? errorColor : '#999'}>
            {rest?.value?.length || 0}/{maxLength}
          </Font>
        )}
      </ErrorRow>
    </div>
  );
};

const StyledTextArea = styled.textarea<{ error?: boolean; fullWidth?: boolean }>`
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};
  height: auto;
  min-height: 89px;
  max-height: 210px;
  display: block;
  padding: 20px 0;
  font-size: 16px;
  font-weight: 500;
  line-height: 150%; /* 30px */
  letter-spacing: -0.2px;
  color: #e6e6e6;
  outline: none;
  transition: 0.2s;
  border-bottom: 1px solid ${({ error }) => (error ? `${errorColor} !important` : '#525252')};
  resize: none;

  ${media.sm} {
    min-height: inherit;
    font-size: 20px;
  }

  ::-webkit-scrollbar {
    display: none;
  }

  &:focus {
    border-bottom: 1px solid #1b46db;
  }

  &::placeholder {
    color: #999;
  }
`;

const ErrorRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

interface InputProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  fullWidth?: boolean;
  error?: boolean;
  errorText?: string;
  value?: string;
  css?: ReturnType<typeof emotionCss>;
}

export default TextFields;
