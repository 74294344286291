import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { css } from '@emotion/react';
import { Helmet } from 'react-helmet-async';
// component
import Font from 'components/atoms/Font';
import Layout from 'components/Layout';
import Spacing from 'components/atoms/Spacing';
import Icon from 'components/atoms/Icon';
import styled from '@emotion/styled';
import Button from 'components/atoms/Button';
import Input from 'components/atoms/Input';
import TextFields from 'components/atoms/TextFields';
import Checkbox from 'components/atoms/Checkbox';
import SuccessModal from 'components/domains/evaluation/SuccessModal';
import DropDown from 'components/atoms/dropdown/DropDown';
// util
import * as regexUtil from 'util/regex';
// style
import { media } from 'styles/cryptodartTheme';
// api
import { request } from 'api';

const Request = () => {
  const commonFormList = [
    {
      name: 'corporationName',
      placeholder: '등록된 재단 혹은 법인명을 입력해 주세요.',
      displayName: '법인명 혹은 재단명',
      required: true,
    },
    {
      name: 'representativeName',
      placeholder: '재단 혹은 법인의 담당자 성함을 입력해 주세요.',
      displayName: '담당자 성함',
      required: true,
    },
    {
      name: 'contactNumber',
      placeholder: '연락처를 입력해 주세요.',
      displayName: '연락처',
      required: true,
      regex: regexUtil.PHONE_NUMBER,
    },
    {
      name: 'email',
      placeholder: '예시) abc@google.com',
      displayName: '이메일',
      required: true,
      regex: regexUtil.EMAIL,
    },
    {
      name: 'title',
      placeholder: '신청 재단 혹은 법인의 담당자 직함을 입력해 주세요.',
      displayName: '직함',
    },
    {
      name: 'projectName',
      placeholder: '해당 없는 경우 "해당 없음" 으로 기재해 주세요.',
      displayName: '프로젝트명',
    },
    {
      name: 'coinName',
      placeholder: '해당 없는 경우 "해당 없음" 으로 기재해 주세요.',
      displayName: '코인명',
    },
  ];

  const [inquiryTypeList, setInquiryTypeList] = useState<InquiryTypes[]>([]);
  const [inquiryType, setInquiryType] = useState<InquiryTypes>('디지털자산 신용 평가');
  const [form, setForm] = useState({
    corporationName: {
      value: '',
      error: false,
    },
    representativeName: {
      value: '',
      error: false,
    },
    contactNumber: {
      value: '',
      error: false,
    },
    email: {
      value: '',
      error: false,
    },
    title: {
      value: '',
      error: false,
    },
    projectName: {
      value: '',
      error: false,
    },
    coinName: {
      value: '',
      error: false,
    },
  });
  const [detail, setDetail] = useState('');
  const [agreePrivacyPolicy, setAgreePrivacyPolicy] = useState({
    checked: false,
    error: false,
  });
  const [isModalVisible, setIsModalVisible] = useState(false);

  /**
   * form 유효성 검사
   * @param value 입력값
   * @param isRequired 필수값 여부
   * @param regex 정규표현식
   */
  const checkFormValidation = ({
    value,
    isRequired,
    regex,
  }: {
    value: string;
    isRequired?: boolean;
    regex?: RegExp;
  }) => {
    // required를 먼저 검사
    if (isRequired && value === '') {
      return false;
    }

    // 정규표현식이 있다면 유효성 검사 진행
    if (regex) {
      return regex.test(value);
    }

    return true;
  };

  /**
   * 01012345678 -> 010-1234-5678 형식으로 변환
   * @param input
   */
  const formatPhoneNumber = input => {
    // 숫자만 있는 경우, 입력된 번호를 형식에 맞게 변환
    let formattedInput = input.replace(/\D/g, ''); // 숫자가 아닌 모든 문자 제거

    // 숫자만 입력된 경우, 형식 적용
    if (formattedInput.length > 3 && formattedInput.length <= 7) {
      formattedInput = formattedInput.replace(/^(\d{3})(\d+)/, '$1-$2');
    } else if (formattedInput.length > 7) {
      formattedInput = formattedInput.replace(/^(\d{3})(\d{4})(\d+)/, '$1-$2-$3');
    }

    return formattedInput;
  };

  const handleChangeForm = (
    e: React.ChangeEvent<HTMLInputElement>,
    regex?: RegExp,
    required?: boolean,
  ) => {
    const { name, value } = e.target;
    const validation = checkFormValidation({ value, regex, isRequired: required });
    if (name === 'contactNumber') {
      setForm(prev => ({
        ...prev,
        [name]: {
          value: formatPhoneNumber(value),
          error: !validation,
        },
      }));
      return;
    }
    setForm(prev => ({
      ...prev,
      [name]: {
        value,
        error: !validation,
      },
    }));
  };

  const resetForm = () => {
    setInquiryType(inquiryTypeList[0]);
    setForm({
      corporationName: {
        value: '',
        error: false,
      },
      representativeName: {
        value: '',
        error: false,
      },
      contactNumber: {
        value: '',
        error: false,
      },
      email: {
        value: '',
        error: false,
      },
      title: {
        value: '',
        error: false,
      },
      projectName: {
        value: '',
        error: false,
      },
      coinName: {
        value: '',
        error: false,
      },
    });
    setDetail('');
    setAgreePrivacyPolicy({
      checked: false,
      error: false,
    });
    sessionStorage.removeItem('formState');
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
    resetForm();
  };

  const handleSubmit = async () => {
    if (!agreePrivacyPolicy.checked) {
      setAgreePrivacyPolicy(prev => ({ ...prev, error: true }));
      return;
    }
    setAgreePrivacyPolicy(prev => ({ ...prev, error: false }));
    const {
      corporationName,
      coinName,
      representativeName,
      projectName,
      title,
      email,
      contactNumber,
    } = form;

    const body = {
      inquiry_type: inquiryType,
      information: {
        corporation_name: corporationName.value,
        representative_name: representativeName.value,
        email: email.value,
        contact_number: contactNumber.value,
        title: title.value || null,
        project_name: projectName.value || null,
        coin_name: coinName.value || null,
      },
      inquiry_detail: detail || null,
    };

    await request
      .post('/form', {
        ...body,
      })
      .then(res => {
        if (res.data.msg === 'ok') {
          setIsModalVisible(true);
        }
      });
  };

  /**
   * 문의 유형 리스트 조회
   */
  const getInquiryType = async () => {
    await request.get('/form').then(res => {
      setInquiryTypeList(res.data.inquiry_type);
      setInquiryType(res.data.inquiry_type[0]);
    });
  };

  /**
   * sessionStorage에 저장된 데이터가 있을 경우 셋팅 페이지 마운트 시 form과 문의 상세내용 셋팅
   */
  const settingFormMount = () => {
    const formState = sessionStorage.getItem('formState');
    if (formState) {
      const { form, detail } = JSON.parse(formState);
      setForm(form);
      setDetail(detail);
    }
  };

  useEffect(() => {
    getInquiryType();
    settingFormMount();
  }, []);

  useEffect(() => {
    // form과 문의 상세내용 변경 시 sessionStorage에 저장
    const formState = {
      form: {
        ...form,
      },
      detail,
    };

    const formValues = Object.values(formState.form);
    const isFormFilled = formValues.every(item => item.value === '');

    // 폼이 전부 비어있을 시 stoage를 셋팅하지 않음
    if (isFormFilled) {
      sessionStorage.removeItem('formState');
      return;
    }

    const json = JSON.stringify(formState);

    sessionStorage.setItem('formState', json);
  }, [form, detail]);

  return (
    <>
      <Helmet>
        <title>평가신청 | 크립토다트</title>
      </Helmet>
      <Layout
        css={css`
          padding: 160px 24px 80px;

          ${media.sm} {
            padding: 268px 24px 160px;
          }
          ${media.md} {
            padding: 290px 24px 160px;
          }
          ${media.lg} {
            padding: 290px 0 160px 0;
          }
        `}
      >
        <Font
          as="h1"
          type="Heading3"
          css={css`
            text-align: center;
          `}
        >
          크립토다트 평가 신청
        </Font>
        <Spacing size={64} />
        <SectionTitle>
          <Icon
            css={css`
              letter-spacing: -1em;
            `}
            size={24}
            type="solid"
            className="Mail-Check-ou-lc"
          >
            Mail Check ou-lc
          </Icon>
          <Font type="Subtitle2">문의 내용을 선택하세요.</Font>
        </SectionTitle>
        <Spacing size={24} />
        <TabButtonContainer>
          {inquiryTypeList.map((type, index) => (
            <Button
              key={index}
              className={`tab ${inquiryType === type ? 'selected' : ''}`}
              css={css`
                white-space: nowrap;
              `}
              onClick={() => setInquiryType(type)}
            >
              {type}
            </Button>
          ))}
        </TabButtonContainer>
        <DropDown<InquiryTypes>
          value={inquiryType}
          onChange={setInquiryType}
          css={css`
            display: block;
            ${media.sm} {
              display: none;
            }
          `}
        >
          <DropDown.Trigger>{inquiryType}</DropDown.Trigger>
          <DropDown.List>
            {inquiryTypeList.map((item, index) => (
              <DropDown.Item key={index} value={item}>
                {item}
              </DropDown.Item>
            ))}
          </DropDown.List>
        </DropDown>
        <Spacing size={64} />
        <SectionTitle>
          <Icon size={24} type="solid">
            Pencil
          </Icon>
          <Font type="Subtitle2">기본 정보를 입력해 주세요.</Font>
        </SectionTitle>
        <Spacing size={32} />
        <CommonInfoForm>
          {commonFormList.map((item, index) => (
            <div
              key={index}
              css={css`
                width: 100%;
                display: flex;
                flex-direction: column;
                gap: 8px;
                margin-bottom: 30px;
                ${media.md} {
                  flex-direction: row;
                  gap: 24px;
                }
              `}
            >
              <FormLabel htmlFor={item.name}>
                <Font type="Body1">{item.displayName}</Font>
                {item.required && (
                  <Font type="Caption" textColor="#9B9B9B">
                    (필수)
                  </Font>
                )}
              </FormLabel>

              <div
                css={css`
                  flex: 1;
                `}
              >
                <Input
                  id={item.name}
                  fullWidth
                  name={item.name}
                  placeholder={item.placeholder}
                  error={form[item.name].error}
                  errorText="올바른 형식으로 다시 입력해주세요."
                  value={form[item.name].value}
                  onChange={e => handleChangeForm(e, item.regex, item.required)}
                />
              </div>
            </div>
          ))}
        </CommonInfoForm>
        <Spacing size={64} />

        <SectionTitle>
          <Icon size={24} type="solid">
            Pencil
          </Icon>
          <Font type="Subtitle2">문의하실 상세 내용을 입력해 주세요.</Font>
        </SectionTitle>
        <Spacing size={64} />
        <TextFields
          fullWidth
          placeholder="문의하실 상세 내용을 입력해주시면 더 빠른 시일 안에 회신 드릴 수 있습니다."
          maxLength={500}
          value={detail}
          onChange={e => setDetail(e.target.value)}
        />
        <Spacing size={64} />
        <CheckboxContainer>
          <div>
            <Checkbox
              id="privacyPolicy"
              label={
                <>
                  <Font type="Body1">개인정보처리방침 동의 </Font>
                  <Link
                    to="/privacy"
                    target="_blank"
                    css={css`
                      &:hover {
                        height: 100%;
                        span {
                          opacity: 0.8;
                          transition: 0.2s;
                        }
                      }
                    `}
                  >
                    <Font type="Caption" textColor="#E6EBEE">
                      (읽어보기)
                    </Font>
                  </Link>
                </>
              }
              checked={agreePrivacyPolicy.checked}
              onClick={() => setAgreePrivacyPolicy(prev => ({ ...prev, checked: !prev.checked }))}
            />
          </div>
          {agreePrivacyPolicy.error && (
            <>
              <Spacing size={8} />
              <div>
                <Font type="Caption" textColor="#D6535B">
                  개인정보 처리방침 동의가 필요합니다.
                </Font>
              </div>
            </>
          )}
        </CheckboxContainer>
        <Spacing size={24} />
        <div
          css={css`
            text-align: center;
          `}
        >
          <Button onClick={handleSubmit}>상담 접수하기</Button>
        </div>
        <SuccessModal isOpen={isModalVisible} onClose={handleCloseModal} />
      </Layout>
    </>
  );
};

const SectionTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const TabButtonContainer = styled.div`
  display: none;
  gap: 4px;
  min-height: 78px;
  width: 100%;
  overflow-x: auto;

  ${media.sm} {
    display: flex;
  }
  &::-webkit-scrollbar {
    display: none;
  }
`;

const CommonInfoForm = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;
`;

const FormLabel = styled.label`
  width: 224px;
  display: flex;
  align-items: center;
  gap: 4px;
`;

const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

type InquiryTypes =
  | '디지털자산 신용 평가'
  | '디지털자산 법률 검토'
  | '블록체인 컨설팅'
  | '프로젝트 개발'
  | '사업 제휴'
  | '기타';

export default Request;
