import { css } from '@emotion/react';
import styled from '@emotion/styled';
// components
import Layout from 'components/Layout';
import Font from 'components/atoms/Font';
import Spacing from 'components/atoms/Spacing';
// styles
import { media } from 'styles/cryptodartTheme';

const DATA = [
  { id: 1, title: '업무 문의', contents: '디지털자산 평가 문의 및 상담 진행' },
  { id: 2, title: '계약 체결', contents: '평가 신청서 작성 및 계약 체결' },
  { id: 3, title: '자료 제출', contents: '신청서 및 평가 항목별 자료 제출' },
  { id: 4, title: '1차 평가 진행', contents: '자료 평가 및 재단 회계 실사 진행' },
  { id: 5, title: '2차 평가 진행', contents: '평가 관련 수정 및 보완 자료 요청' },
  {
    id: 6,
    title: '디지털자산 평가 보고서 제공',
    contents: '결과 보고서 제공 및 공개 여부 협의',
  },
];
const Section4 = () => {
  return (
    <Layout
      css={css`
        text-align: center;
        padding: 160px 24px;
        ${media.lg} {
          padding: 160px 0;
        }
      `}
    >
      <Font type="Heading2">크립토다트 평가 절차 안내</Font>
      <Spacing size={64} />
      <div
        css={css`
          display: grid;
          gap: 16px;
          grid-template-columns: 1fr;
          ${media.sm} {
            grid-template-columns: repeat(2, 1fr);
          }
          ${media.lg} {
            grid-template-columns: repeat(3, 1fr);
          }
        `}
      >
        {DATA.map(data => (
          <Card key={data.title} num={data.id}>
            <Font
              type="Subtitle2"
              css={css`
                color: #c1d5e4 !important;
                letter-spacing: -0.24px;
              `}
            >
              {data.title}
            </Font>
            <Font
              type="Subtitle1"
              css={css`
                color: #eef2ff !important;
                letter-spacing: -0.28px;
              `}
            >
              {data.contents}
            </Font>
          </Card>
        ))}
      </div>
    </Layout>
  );
};

const Card = styled.div<{ num: number }>`
  padding: 24px 0;
  display: flex;
  gap: 8px;
  flex-direction: column;
  text-align: left;
  border-bottom: 4px solid #1b46db;
  background-image: ${props => `url(/images/intro/section4_${props.num}.png)`};
  background-size: 97px 167px;
  background-position: right -12px bottom -48px;

  ${media.sm} {
    padding: 24px;
    gap: 32px;
  }
  ${media.md} {
    gap: 32px;
    padding: 44px;
    background-size: 139px 239px;
    background-position: right -32px bottom -56px;
  }
`;

export default Section4;
