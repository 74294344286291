import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { css, ThemeProvider } from '@emotion/react';
import { HelmetProvider } from 'react-helmet-async';
// component
import Header from 'components/common/Header';
import Footer from 'components/common/Footer';
import Home from 'pages/Home';
import Request from 'pages/Request';
import About from 'pages/About';
import Evaluation from 'pages/Evaluation';
import Legal from 'pages/Legal';
import Privacy from 'pages/Privacy';
import ScrollToTop from 'components/common/ScrollToTop';
// styles
import GlobalStyle from 'styles/GlobalStyle';
import { cryptodartTheme } from 'styles/cryptodartTheme';

const App = () => {
  return (
    <HelmetProvider>
      <ThemeProvider theme={cryptodartTheme}>
        <GlobalStyle />
        <BrowserRouter>
          <Header />
          <ScrollToTop />
          <div
            css={css`
              // 콘텐츠 높이가 짧을 시 푸터가 화면 하단에 위치하도록 설정
              min-height: 100vh;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
            `}
          >
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/request" element={<Request />} />
              <Route path="/about" element={<About />} />
              <Route path="/evaluation" element={<Evaluation />} />
              <Route path="/legal" element={<Legal />} />
              <Route path="/privacy" element={<Privacy />} />
            </Routes>
            <Footer />
          </div>
        </BrowserRouter>
      </ThemeProvider>
    </HelmetProvider>
  );
};

export default App;
