import Section1 from 'components/main/Section1';
import Section2 from 'components/main/Section2';
import Section3 from 'components/main/Section3';
import Section4 from 'components/main/Section4';
import { Helmet } from 'react-helmet-async';

const Home = () => {
  return (
    <>
      <Helmet>
        <title>크립토다트ㅣ신뢰할 수 있는 암호화폐 신용평가 기업</title>
      </Helmet>
      <Section1 />
      <Section2 />
      <Section3 />
      <Section4 />
    </>
  );
};

export default Home;
