import { css } from '@emotion/react';
import styled from '@emotion/styled';
// components
import Layout from 'components/Layout';
import Font from 'components/atoms/Font';
// style
import { media } from 'styles/cryptodartTheme';

const Section1 = () => {
  return (
    <Wrapper>
      <Layout
        css={css`
          display: flex;
          flex-direction: column;
          justify-content: end;
          height: 100%;
          padding: 120px 32px;
          ${media.sm} {
            padding: 120px 40px;
          }
          ${media.md} {
            padding-bottom: 120px;
          }
        `}
      >
        <Font type="Heading0">Where Crypto Meets Credibility</Font>
        <Font type="Heading0">Your Trusted Rating Partner</Font>
      </Layout>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background: url('/images/intro/section1_bg.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh;
`;

export default Section1;
