import { InputHTMLAttributes } from 'react';
import { css as emotionCss } from '@emotion/react';
import styled from '@emotion/styled';
// component
import Font from './Font';
import Spacing from './Spacing';
// style
import { media } from 'styles/cryptodartTheme';

const errorColor = '#D6535B';

const Input = ({ error, errorText, css, ...rest }: InputProps) => {
  return (
    <InputContainer>
      <StyledInput css={css} error={error} {...rest} />
      {error && (
        <Font
          css={emotionCss`
            position: absolute;
            top: calc(100% + 8px);
            font-size: 16px;
            letter-spacing: 0.16px;
          `}
          type="Caption"
          textColor={errorColor}
        >
          {errorText}
        </Font>
      )}
    </InputContainer>
  );
};

const InputContainer = styled.div`
  position: relative;
`;

const StyledInput = styled.input<{ error?: boolean; fullWidth?: boolean }>`
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};
  display: block;
  height: 68px;
  padding: 20px 0;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.2px;
  color: #e6e6e6;
  outline: none;
  transition: 0.2s;
  border-bottom: 1px solid ${({ error }) => (error ? `${errorColor} !important` : '#525252')};
  ${media.sm} {
    height: 70px;
    font-size: 20px;
  }

  &:focus {
    border-bottom: 1px solid #1b46db;
  }

  &::placeholder {
    color: #999;
  }
`;

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  fullWidth?: boolean;
  error?: boolean;
  errorText?: string;
  css?: ReturnType<typeof emotionCss>;
}

export default Input;
