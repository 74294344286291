import React, { PropsWithChildren } from 'react';
import { css } from '@emotion/react';
// component
import Font from 'components/atoms/Font';
import Icon from 'components/atoms/Icon';
// hook
import useDropdownContext from 'hook/useDropdownContext';

const DropDownTrigger = ({ children }: PropsWithChildren) => {
  const context = useDropdownContext();
  const { isOpen, handleOpen, handleClose } = context;

  return (
    <div>
      <Font
        type="Body1"
        onClick={!isOpen ? handleOpen : handleClose}
        css={css`
          position: relative;
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          height: 100%;
          padding: 16px 32px;
          border-radius: 44px;
          background-color: #191919;
          cursor: pointer;
        `}
      >
        {children}
        <Icon
          type="line"
          css={css`
            display: inline;
            transition: 0.21s ease-in-out;
            ${isOpen &&
            css`
              transform: rotate(180deg);
            `};
          `}
        >
          Chevron Down
        </Icon>
      </Font>
    </div>
  );
};

export default DropDownTrigger;
