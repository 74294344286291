import styled from '@emotion/styled';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
// components
import SideBar from './SideBar';
import Icon from 'components/atoms/Icon';
// styles
import { media } from 'styles/cryptodartTheme';

const PAGE = [
  { id: 1, name: '소개', link: '/about' },
  { id: 2, name: '디지털자산 평가', link: '/evaluation' },
  { id: 3, name: '법률 검토', link: '/legal' },
  { id: 4, name: '상담 신청', link: '/request' },
];

const Header = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isScroll, setIsScroll] = useState<boolean>(false);

  const handleOpen = () => {
    setIsOpen(!isOpen);
  };
  const onHeaderBarMoved = e => {
    const window = e.currentTarget;
    if (window.scrollY > 0) setIsScroll(true);
    else setIsScroll(false);
  };

  useEffect(() => {
    window.addEventListener('scroll', e => onHeaderBarMoved(e));
    return () => {
      window.removeEventListener('scroll', e => onHeaderBarMoved(e));
      setIsScroll(false);
    };
  }, []);

  return (
    <Wrapper isOpen={isOpen} isScroll={isScroll}>
      <Link to="/">
        <LogoImg src="/images/logo.png" />
      </Link>
      <MenuWrapper>
        {PAGE.map(page => (
          <StyledLink to={page?.link} key={page.id}>
            {page.name}
          </StyledLink>
        ))}
      </MenuWrapper>
      <IconWrapper onClick={handleOpen}>
        <Icon size={40}>{isOpen ? 'Close' : 'Menu'}</Icon>
        {isOpen && <SideBar item={PAGE} setIsOpen={setIsOpen} />}
      </IconWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.header<{ isScroll: boolean; isOpen: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
  width: 100%;
  padding: 16px;
  display: flex;
  background: ${({ isOpen, isScroll }) =>
    isOpen ? 'black' : isScroll ? 'rgba(0, 0, 0, 0.16)' : 'transparent'};
  justify-content: space-between;
  backdrop-filter: ${({ isScroll }) => (isScroll ? 'blur(6px)' : 'none')};
  ${media.sm} {
    padding: 28px 24px;
  }

  ${media.lg} {
    background: transparent;
    padding: 39px 32px;
  }
`;
const LogoImg = styled.img`
  height: 40px;
  ${media.sm} {
    height: 48px;
  }
`;

const MenuWrapper = styled.span`
  display: none;
  ${media.lg} {
    display: flex;
    gap: 24px;
  }
`;
const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  padding: 8px;
  transition: 200ms;
  cursor: pointer;

  ${media.lg} {
    display: none;
  }
`;

const StyledLink = styled(Link)`
  line-height: 1.2 !important;
  padding: 16px 20px;
  border-radius: 16px;
  color: rgba(255, 255, 255, 0.68);
  transition: 200ms;
  :hover {
    background: rgba(255, 255, 255, 0.12);
  }
  .active {
    color: #fff;
  }
`;
export default Header;
