import { useEffect, useRef } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
// components
import Font from 'components/atoms/Font';
import Spacing from 'components/atoms/Spacing';
// style
import { media } from 'styles/cryptodartTheme';

const SideBar = ({ item, setIsOpen }: Props) => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    document.documentElement.style.overflow = 'hidden';
    return () => {
      document.documentElement.style.overflow = 'unset';
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = e => {
      e.stopPropagation();

      // Close 버튼 클릭 시에는 Header 컴포넌트에서 isOpen handler 함수를 통해 닫힘
      if (e?.target?.innerText === 'Close') {
        return;
      }

      if (wrapperRef.current && !wrapperRef.current.contains(e.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside, { capture: true });

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef]);

  return (
    <OuterWrapper>
      <Wrapper ref={wrapperRef}>
        {item.map(page => (
          <List key={page.id} to={page.link}>
            <Font type="Subtitle2">{page.name}</Font>
          </List>
        ))}
        <Spacing size={32} />
        <Contents>
          <List to="mailto:david@cryptodart.com">
            <Font
              type="Subtitle2"
              css={css`
                letter-spacing: -0.24px;
                color: rgba(255, 255, 255, 0.68);
              `}
            >
              제휴 문의
            </Font>
          </List>
          <List to="/privacy">
            <Font
              type="Subtitle2"
              css={css`
                letter-spacing: -0.24px;
                color: rgba(255, 255, 255, 0.68);
              `}
            >
              개인정보 처리방침
            </Font>
          </List>
        </Contents>
      </Wrapper>
    </OuterWrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  background-color: black;
  padding: 24px;

  ${media.sm} {
    padding: 48px;
  }
`;

const List = styled(Link)`
  display: flex;
  padding: 12px 16px;
  border-radius: 16px;
  :hover {
    background: rgba(255, 255, 255, 0.12);
  }
`;

const Contents = styled.div`
  border-top: 1px solid rgba(255, 255, 255, 0.12);
  padding: 16px 0;
`;

const OuterWrapper = styled.div`
  position: absolute;
  top: 80px;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(35, 35, 35, 0.87);

  ${media.sm} {
    top: 104px;
  }
`;

interface Props {
  item: {
    id: number;
    name: string;
    link: string;
  }[];
  setIsOpen: (isOpen: boolean) => void;
}

export default SideBar;
