import { css } from '@emotion/react';
import { Helmet } from 'react-helmet-async';
// components
import Layout from 'components/Layout';
import Font from 'components/atoms/Font';
import Spacing from 'components/atoms/Spacing';
// styles
import { media } from 'styles/cryptodartTheme';

const LegalReviews = () => {
  return (
    <>
      <Helmet>
        <title>법률검토 | 크립토다트</title>
      </Helmet>
      <Layout
        css={css`
          padding: 160px 24px 80px;
          ${media.sm} {
            padding: 268px 24px 160px;
          }
          ${media.md} {
            max-width: 895px;
            padding: 290px 0 160px;
          }
        `}
      >
        <div
          css={css`
            display: flex;
            flex-direction: column;
            gap: 26px;
          `}
        >
          <Font type="Heading2">법률 검토</Font>
          <Font type="Subtitle1">증권성 여부 검토</Font>
        </div>
        <Spacing size={44} />
        <div
          css={css`
            display: flex;
            flex-direction: column;
            gap: 12px;
          `}
        >
          <Font type="Body1">
            법률 부문에서 가장 큰 주의를 기울여야 할 점은 디지털자산의 증권성 여부입니다. 2023년
            4월, 미국증권거래위원회 (SEC)는 암호화폐 거래소인 바이낸스와 코인베이스에 대해 증권법
            위반 혐의 소송을 제기했으며, 이로 인해 증권으로 분류될 가능성이 있는 디지털자산 중
            일부인 솔라나, 폴리곤, 카르다노 등이 거래소에서 상장 폐지되었습니다. 국내 거래소 또한
            기존에 상장된 디지털자산의 발행사들에게 증권성 여부와 관련된 법률 의견서를 요구하고
            있습니다.
          </Font>
          <Font type="Body1">
            사업 기획 초기에 디지털자산이 증권으로 분류되는지 여부를 검토하면 향후 증권으로 해석되어
            상장 폐지되는 위험을 방지할 수 있습니다. 크립토다트는 법률 전문가인 법무법인 대륙아주와
            협력하여 증권성 여부를 검토하는 서비스를 제공합니다. 이를 통해 고객은 법적 규정을
            준수하고 법적 문제를 사전에 예방할 수 있습니다.
          </Font>
        </div>
      </Layout>
    </>
  );
};

export default LegalReviews;
