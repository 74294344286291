import { css } from '@emotion/react';
import styled from '@emotion/styled';
// components
import Layout from 'components/Layout';
import Font from 'components/atoms/Font';
import Icon from 'components/atoms/Icon';
import Spacing from 'components/atoms/Spacing';
// styles
import { media } from 'styles/cryptodartTheme';

const ITEM = [
  {
    title: '신용 평가',
    content: '회계 소프트웨어를 통한 재무 건전성 검증 및 토크노믹스 심층 분석',
    icon: 'Bank',
    color: '#f7e274',
  },
  {
    title: '기술 심사',
    content: '발행사의 전체 코드 보안 감사를 통한 기술력 검증',
    icon: 'Moderator',
    color: '#6981FF',
  },
  {
    title: '법률 검토',
    content: '법무법인 대륙아주와의 협력을 통한 증권성 여부 검토',
    icon: 'Folder Check ou-lc',
    color: '#0075FF',
  },
  {
    title: '전자 공시',
    content: '발행사의 공시 정확성 검증 및 공시 플랫폼 운영',
    icon: 'Align Box Top Left',
    color: '#FF9A3D',
  },
];

const Business = () => {
  return (
    <Layout
      css={css`
        padding: 80px 24px;
        ${media.md} {
          padding: 100px 24px;
        }
        ${media.lg} {
          padding: 160px 0;
        }
      `}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          gap: 8px;
        `}
      >
        <Font type="Subtitle2">소개</Font>
        <Font type="Heading2">주요 사업</Font>
      </div>
      <Spacing size={44} />
      <div
        css={css`
          display: grid;
          gap: 16px;
          grid-template-columns: 1fr;
          ${media.sm} {
            grid-template-columns: repeat(2, 1fr);
          }
        `}
      >
        {ITEM.map((item, index) => (
          <span key={index}>
            <Icon
              type="solid"
              size={87}
              css={css`
                color: ${item.color};
                margin: 12px 24px;
                letter-spacing: -1rem;
                width: 72px;
                height: 72px;
                font-size: 72px;

                ${media.sm} {
                  width: 87px;
                  height: 87px;
                  font-size: 87px;
                }
              `}
            >
              {item.icon}
            </Icon>
            <TextWrapper>
              <Font
                type="Subtitle2"
                css={css`
                  color: #9d9d9d;
                `}
              >
                {item.title}
              </Font>
              <Font type="Heading3">{item.content}</Font>
            </TextWrapper>
          </span>
        ))}
      </div>
    </Layout>
  );
};

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 24px 32px;
`;

export default Business;
