/**
 * 휴대폰번호 유효성 패턴 정규식
 * @example 010-1234-5678, 01012345678
 */
export const PHONE_NUMBER = /^(?:\d{3}-\d{4}-\d{4}|\d{11})$/i;

/**
 * 이메일 유효성 검사 패턴 정규식
 */
export const EMAIL =
  /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i;
