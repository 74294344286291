import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
// components
import Font from 'components/atoms/Font';
import Spacing from 'components/atoms/Spacing';
// style
import { media } from 'styles/cryptodartTheme';

const PAGE = [
  {
    id: 1,
    name: '제휴 문의',
    link: 'mailto:david@cryptodart.com',
  },
  {
    id: 2,
    name: '개인정보 처리방침',
    link: '/privacy',
  },
];

const Footer = () => {
  return (
    <Wrapper>
      <Container>
        <div
          css={css`
            display: flex;
            flex-direction: column;
            gap: 16px;
            padding: 32px 0;
            border-bottom: 1px solid rgba(255, 255, 255, 0.18);
            justify-content: flex-start;
            align-items: flex-start;

            ${media.md} {
              display: flex;
              flex-direction: row;
              gap: 0;
              justify-content: space-between;
              align-items: center;
            }
          `}
        >
          <Logo src="/images/footer/logo.png" />
          <div
            css={css`
              display: flex;
              flex-direction: column;
              gap: 8px;
              width: 100%;
              ${media.md} {
                flex-direction: row;
                justify-content: flex-end;
                gap: 16px;
              }
            `}
          >
            {PAGE.map(page => (
              <StyledLink to={page?.link} key={page.id}>
                <Font
                  type="Button"
                  css={css`
                    letter-spacing: -0.4px;
                  `}
                >
                  {page.name}
                </Font>
              </StyledLink>
            ))}
          </div>
        </div>

        <Contents>
          <Font
            type="Body1"
            css={css`
              letter-spacing: -0.2px;
            `}
          >
            주식회사 크립토다트
          </Font>
          <Spacing size={24} />
          <div
            css={css`
              gap: 8px;
              display: flex;
              flex-direction: column;
            `}
          >
            <div
              css={css`
                gap: 8px;
                display: flex;
              `}
            >
              <InfoFont weight={700}>대표자</InfoFont>
              <InfoFont weight={400}>오도현, 황복연</InfoFont>
            </div>
            <div
              css={css`
                gap: 8px;
                display: flex;
              `}
            >
              <InfoFont weight={700}>사업자등록번호</InfoFont>
              <InfoFont weight={400}>212-87-02581</InfoFont>
            </div>
            <div
              css={css`
                display: flex;
                flex-direction: column;
                gap: 24px;
                ${media.md} {
                  justify-content: space-between;
                  align-items: center;
                  flex-direction: row;
                }
              `}
            >
              <span
                css={css`
                  gap: 8px;
                  display: flex;
                `}
              >
                <InfoFont weight={700}>주소</InfoFont>
                <InfoFont weight={400}>서울특별시 강남구 논현로98길 28, 4층</InfoFont>
              </span>
              <span>
                <CopyRightFont>Cryptodart. All rights reserved.</CopyRightFont>
              </span>
            </div>
          </div>
        </Contents>
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background: #1b46db;
`;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 16px;
  ${media.lg} {
    padding: 32px 0;
  }
  ${media.md} {
    padding: 0 24px;
  }
`;

const Logo = styled.img`
  object-fit: contain;
  height: 16px;

  ${media.lg} {
    height: 21px;
  }
`;

const StyledLink = styled(Link)`
  width: 100%;
  padding: 12px 20px;
  border-radius: 16px;
  color: #fff;
  transition: 200ms;
  :hover {
    background: rgba(255, 255, 255, 0.12);
  }
  ${media.md} {
    width: auto;
  }
`;

const Contents = styled.div`
  padding: 32px 0;
`;

const InfoFont = styled.span<{ weight: number }>`
  color: #fff;
  font-size: 14px;
  font-weight: ${({ weight }) => weight};
  line-height: 150%;
`;

const CopyRightFont = styled.span`
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  line-height: 135%;
  letter-spacing: 0.14px;
`;

export default Footer;
