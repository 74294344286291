import { ButtonHTMLAttributes } from 'react';
import styled from '@emotion/styled';
// component
import Font from 'components/atoms/Font';

const Button = ({ children, className = 'button', ...rest }: ButtonProps) => {
  return (
    <StyledButton className={className} {...rest}>
      <Font type="Button">{children}</Font>
    </StyledButton>
  );
};

const StyledButton = styled.button`
  padding: 24px 44px;
  border-radius: 44px;
  background: #1b46db;
  transition: 0.2s;

  :hover {
    background: rgba(27, 70, 219, 0.68);
  }

  :disabled {
    cursor: not-allowed;
    background: #2b2c2f;
    span {
      color: #666;
    }
  }

  &.tab {
    padding: 24px 32px;
    background: none;
    :hover {
      background: #191919;
    }

    &.selected {
      background: #1b46db;
      :hover {
        background: rgba(27, 70, 219, 0.68);
      }
    }
  }
`;

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  className?: string;
}

export default Button;
