import { forwardRef, HTMLAttributes, ReactNode, Ref } from 'react';
import styled from '@emotion/styled';

// eslint-disable-next-line react/display-name
const Layout = forwardRef(
  ({ children, ...rest }: Props & HTMLAttributes<HTMLDivElement>, ref?: Ref<HTMLDivElement>) => {
    return (
      <LayoutContainer ref={ref} {...rest}>
        {children}
      </LayoutContainer>
    );
  },
);

const LayoutContainer = styled.main`
  position: relative;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
`;

interface Props {
  children: ReactNode;
}

export default Layout;
