import { css } from '@emotion/react';
import styled from '@emotion/styled';
// components
import Layout from 'components/Layout';
import Font from 'components/atoms/Font';
import Spacing from 'components/atoms/Spacing';
// style
import { media } from 'styles/cryptodartTheme';

const IMG = [
  {
    src: '/images/fund.png',
    alt: 'partner1',
    color: '#fff',
  },
  {
    src: '/images/ajoo.png',
    alt: 'partner2',
    color: '#00A6D0',
  },
  {
    src: '/images/coinplug.png',
    alt: 'partner3',
    color: '#42609E',
  },
  {
    src: '/images/nice.png',
    alt: 'partner4',
    color: '#1A1A1A',
  },
  {
    src: '/images/code.png',
    alt: 'partner4',
    color: '#FFF',
  },
];

const Partner = () => {
  return (
    <Layout
      css={css`
        padding: 80px 24px;
        ${media.md} {
          padding: 100px 24px;
        }
        ${media.lg} {
          padding: 160px 0;
        }
      `}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          gap: 8px;
        `}
      >
        <Font type="Subtitle2">소개</Font>
        <Font type="Heading2">파트너</Font>
      </div>
      <Spacing size={44} />
      <div
        css={css`
          display: grid;
          gap: 16px;
          grid-template-columns: 1fr;
          ${media.sm} {
            grid-template-columns: repeat(2, 1fr);
          }
          ${media.lg} {
            grid-template-columns: repeat(3, 1fr);
          }
        `}
      >
        {IMG.map((item, index) => (
          <Card key={index} color={item.color}>
            <img src={item.src} alt={item.alt} width={'224px'} />
          </Card>
        ))}
      </div>
    </Layout>
  );
};
const Card = styled.div<{ color: string }>`
  border-radius: 16px;
  padding: 41px 0px;
  background-color: ${({ color }) => color};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default Partner;
