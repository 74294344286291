import { css } from '@emotion/react';
import { Helmet } from 'react-helmet-async';
// component
import Font from 'components/atoms/Font';
import Spacing from 'components/atoms/Spacing';
import Layout from 'components/Layout';
// style
import { media } from 'styles/cryptodartTheme';

const partner = [
  {
    title: '재무 건정성 검증',
    src: '/images/fund.png',
    background: '#FFF',
  },
  {
    title: '법률 검토',
    src: '/images/ajoo.png',
    background: '#00A6D0',
  },
  {
    title: '기술 심사',
    src: '/images/coinplug.png',
    background: '#42609E',
  },
  {
    title: '신용 평가',
    src: '/images/nice.png',
    background: '#232323',
  },
];

const category = [
  {
    title: '커뮤니티',
    list: [
      '디스코드, 텔레그램, 트위터 등 공식 커뮤니티의 규모',
      '커뮤니티의 활성도 및 대화 빈도',
      '재단과 커뮤니티 소통 빈도',
      '커뮤니티의 신규 유입자 및 이탈자 추세',
      '커뮤니티 실제 활동 사용자 비중',
    ],
  },
  {
    title: '온체인 데이터',
    list: [
      '거래량 및 블록체인 활동',
      '프로젝트 토큰 보유 지갑의 수',
      '지갑의 생성 및 폐기 빈도',
      'DApp 활동 및 사용자 수',
      '자전거래, 스테이킹 에어드랍 등 허수를 제외한 실제 블록체인 활성도 추적',
    ],
  },
  {
    title: '기술력',
    list: [
      '발행사 개발팀의 전문성, 경력, 인원 수 검증',
      '핵심 기술의 자체 개발 여부 검증',
      '기술 관련 지적 재산권 보유 여부 검증',
      '기술 지원성 및 호환성 검증',
      '트랜잭션 속도 및 업그레이드 유연성 거증',
      '거버넌스 시스템 검증',
    ],
  },
  {
    title: '토크노믹스',
    list: [
      '토큰 운영 계획의 합리성 검증',
      '토큰 발행량, 유통량, 분배 계획 검증',
      '토큰 관리의 투명성 검증',
      '토큰 락업 계획 이행 여부 검증',
      '토큰의 제 3자 수탁 및 전송 시스템 사용 여부 검증',
    ],
  },
  {
    title: '전문성',
    list: [
      '발행사 법인의 신용도 검증',
      '채무불이행 혹은 세금 연체 이력 존재 여부 검증',
      '동종업계 경력, 기술 지식 수준, 경영 능력 검증',
      '내부 감사위원회 및 외부 감사 선임 여부 검증',
      '디지털 마케및팅 및 미디어 활용 역량 검증',
      '커뮤니티 규모 및 운영 역량 검증',
    ],
  },
  {
    title: '보안',
    list: [
      '개인정보 보호 장치 수립 여부 검증',
      '보안 취약점 관리 여부 검증',
      '위기 대응 체계 검증',
      '보안팀 구성 여부 검증',
      '보안 예방 활동 및 보안 감사서 획득 여부 검증',
      '시스템 향상성 관리 체계 검증',
    ],
  },
  {
    title: '법률',
    list: ['사업 모델의 적법성 검토', '토큰의 증권성 해당 여부 검토'],
  },
];

const Evaluation = () => {
  return (
    <>
      <Helmet>
        <title>자산평가 | 크립토다트</title>
      </Helmet>
      <Layout
        css={css`
          padding: 160px 24px 80px;
          ${media.sm} {
            padding: 228px 24px 120px;
          }
          ${media.lg} {
            padding: 290px 0 160px;
          }
        `}
      >
        <Font as="h1" type="Subtitle2" textColor="#D9D9D9">
          디지털자산 평가
        </Font>
        <Spacing size={8} />
        <Font type="Heading2" textColor="#D9D9D9">
          평가 모델
        </Font>
        <Spacing size={44} />
        <Font type="Subtitle1" textColor="#D9D9D9">
          평가 모델
        </Font>
        <Spacing size={48} />
        <Font type="Subtitle1" textColor="#D9D9D9">
          크립토다트는 분야별 전문가들과 컨소시엄을 구성하여 평가 모델을 고도화하고 있습니다.
          <Spacing size={16} />
          신용 평가는 나이스평가정보, 법률 검토는 대륙아주, 기술 심사는 코인플러그, 자산 실사는
          우리펀드서비스와 협업하여 디지털자산을 정확하게 평가합니다.
        </Font>
        <Spacing size={60} />
        <div
          css={css`
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            ${media.sm} {
              grid-template-columns: repeat(2, 1fr);
            }
            ${media.lg} {
              grid-template-columns: repeat(4, 1fr);
            }
            gap: 16px;
          `}
        >
          {partner.map((item, index) => (
            <p
              key={index}
              css={css`
                flex: 1;
              `}
            >
              <Font type="Body1" textColor="#FFF">
                {item.title}
              </Font>
              <Spacing size={16} />
              <div
                css={css`
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  background: ${item.background};
                  height: 200px;
                  border-radius: 16px;
                `}
              >
                <img
                  src={item.src}
                  css={css`
                    height: 60%;
                  `}
                />
              </div>
            </p>
          ))}
        </div>
      </Layout>
      <Layout
        css={css`
          padding: 80px 24px;
          ${media.sm} {
            padding: 120px 24px;
          }
          ${media.lg} {
            padding: 160px 0;
          }
        `}
      >
        <Font as="h1" type="Subtitle2" textColor="#D9D9D9">
          디지털자산 평가
        </Font>
        <Spacing size={8} />
        <Font type="Heading2" textColor="#D9D9D9">
          평가 항목
        </Font>
        <Spacing size={44} />
        {category.map((item, index) => (
          <div
            key={index}
            css={css`
              margin: 28px 0;
            `}
          >
            <Font type="Subtitle1" textColor="#D9D9D9">
              {item.title}
            </Font>
            <Spacing size={28} />
            <ul
              css={css`
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                row-gap: 16px;
                column-gap: 24px;
                ${media.sm} {
                  gap: 24px;
                }
              `}
            >
              {item.list.map((list, index) => (
                <li
                  key={index}
                  css={css`
                    list-style-type: disc;
                    list-style-position: inside;
                    text-indent: -18px;
                    padding-left: 18px;
                    flex: 1 1 100%;
                    ${media.sm} {
                      flex: 1 1 40%;
                    }
                  `}
                >
                  <Font type="Body1" textColor="#D9D9D9">
                    {list}
                  </Font>
                </li>
              ))}
            </ul>
            <Spacing size={48} />
          </div>
        ))}
      </Layout>
    </>
  );
};

export default Evaluation;
