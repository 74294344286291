export const mediaQuery = (minWidth: number): string => `
	@media (min-width: ${minWidth}px)
`;

export const breakPoints = {
  xl: 1920,
  lg: 1200,
  md: 900,
  sm: 600,
  xs: 0,
} as const;

export const media = {
  xl: mediaQuery(breakPoints.xl),
  lg: mediaQuery(breakPoints.lg),
  md: mediaQuery(breakPoints.md),
  sm: mediaQuery(breakPoints.sm),
  xs: mediaQuery(breakPoints.xs),
};

const colorTheme = {
  // Semantic
  white: '#FFFFFF',

  // Shade
  shade000: '#000',
  shade100: '#161616',
  shade200: '#232323',
  shade300: '#525252',
  shade400: '#8A8A8A',
  shade500: '#9A9A9A',
  shade600: '#CDCED2',
  shade700: '#E3E3E5',
  shade800: '#F5F6F7',
  shade900: '#FBFBFC',

  // Reverse
  reverse54: 'rgba(0,0,0, 0.54)',
  reverse12: 'rgba(0,0,0, 0.12)',
  reverse05: 'rgba(0,0,0, 0.05)',
  reverse02: 'rgba(0,0,0, 0.02)',
  opacity76: 'rgba(255, 255, 255, 0.76)',
  opacity54: 'rgba(255,255,255, 0.54)',
  opacity24: 'rgba(255,255,255, 0.24)',
  opacity12: 'rgba(255,255,255, 0.12)',
  opacity05: 'rgba(255,255,255, 0.05)',
  opacity02: 'rgba(255,255,255, 0.02)',

  // Primary
  primaryFont: '#AB9BFF',
  primary100: '#4A38DB',
  primary87: 'rgba(74, 56, 219, 0.87)',
  primary54: 'rgba(74, 56, 219, 0.54)',
  primary36: 'rgba(74, 56, 219, 0.36)',
  primary24: 'rgba(74, 56, 219, 0.24)',
  primary12: 'rgba(74, 56, 219, 0.12)',

  // Secondary
  secondaryFont: '#DE80D1',
  secondary100: '#DD29C3',
  secondary54: 'rgba(221, 41, 195, 0.54)',
  secondary36: 'rgba(221, 41, 195, 0.36)',
  secondary24: 'rgba(221, 41, 195, 0.24)',
  secondary12: 'rgba(221, 41, 195, 0.12)',

  // Accent
  accentFont: '#FF70B1',
  accent100: '#F72585',
  accent54: 'rgba(247, 37, 133, 0.54)',
  accent36: 'rgba(247, 37, 133, 0.36)',
  accent24: 'rgba(247, 37, 133, 0.24)',
  accent12: 'rgba(247, 37, 133, 0.12)',

  // Purple
  purpleFont: '#CB89FF',
  purple100: '#9626EE',
  purple24: 'rgba(182, 89, 255, 0.24)',
  purple12: 'rgba(182, 89, 255, 0.12)',

  // Orange
  orangeFont: '#FFA048',
  orange100: '#FF7A00',
  orange24: 'rgba(255, 122, 0, 0.24)',
  orange12: 'rgba(255, 122, 0, 0.12)',

  // Warning
  warningFont: '#FFC90B',
  warning100: '#FFAC0B',
  warning24: 'rgba(255, 172, 11, 0.24)',
  warning12: 'rgba(255, 172, 11, 0.12)',

  // Error
  errorFont: '#FF758E',
  error100: '#FF758E',
  error24: 'rgba(255, 117, 142, 0.24)',
  error12: 'rgba(255, 117, 142, 0.12)',

  // Success
  successFont: '#43C09B',
  success100: '#43C09B',
  success24: 'rgba(67, 192, 155, 0.24)',
  success12: 'rgba(67, 192, 155, 0.12)',

  // Info
  infoFont: '#70A0FF',
  info100: '#4B6EEA',
  info24: 'rgba(75, 110, 234, 0.24)',
  info12: 'rgba(75, 110, 234, 0.12)',
};

export type ColorType = keyof typeof colorTheme;

export const cryptodartTheme = {
  breakPoints,
  colorTheme,
};
