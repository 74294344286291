import { css } from '@emotion/react';
import styled from '@emotion/styled';
// component
import Button from 'components/atoms/Button';
import Font from 'components/atoms/Font';
import Spacing from 'components/atoms/Spacing';
import { useEffect } from 'react';

const SuccessModal = ({ isOpen, onClose }: Props) => {
  useEffect(() => {
    if (isOpen) {
      document.documentElement.style.overflow = 'hidden';
    } else {
      document.documentElement.style.overflow = 'auto';
    }
  }, [isOpen]);
  return (
    <Modal isOpen={isOpen}>
      <ModalOverlay className={isOpen ? 'visible' : ''} onClick={onClose} />
      <ModalBody className={isOpen ? 'visible' : ''}>
        <Font type="Subtitle2">
          평가 신청 접수가 완료되었습니다. 입력해 주신 이메일로 회신 드리겠습니다.
        </Font>
        <Spacing size={24} />
        <Button
          onClick={onClose}
          css={css`
            width: 100%;
          `}
        >
          확인
        </Button>
      </ModalBody>
    </Modal>
  );
};

const Modal = styled.div<{ isOpen: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
  visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  transition: all 0.2s;
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  background: none;
  transition: all 0.2s;
  &.visible {
    background: rgba(35, 35, 35, 0.68);
  }
`;

const ModalBody = styled.div`
  position: relative;
  z-index: 1001;
  width: auto;
  max-width: 420px;
  padding: 24px;
  border-radius: 24px;
  background-color: #000;
  visibility: hidden;
  opacity: 0;
  transform: translateY(10%);
  transition: all 0.2s;

  &.visible {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
  }
`;

interface Props {
  isOpen: boolean;
  onClose?: () => void;
}

export default SuccessModal;
