import { ElementType, forwardRef, HTMLAttributes, memo, ReactNode, Ref } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
// style
import { ColorType, media } from 'styles/cryptodartTheme';

// eslint-disable-next-line react/display-name
const Font = forwardRef(
  (
    {
      textColor = 'shade900',
      type,
      children,
      className,
      as,
      css,
      ...rest
    }: HTMLAttributes<HTMLSpanElement> & Props,
    ref?: Ref<HTMLSpanElement>,
  ) => {
    return (
      <CryptoDartFont
        as={as}
        ref={ref}
        css={css}
        className={className}
        $type={type}
        $color={textColor}
        {...rest}
      >
        {children}
      </CryptoDartFont>
    );
  },
);

const generateFontStyle = (type: FontType) => {
  switch (type) {
    case 'Heading0':
      return css`
        font-weight: 800;
        line-height: 1.25;
        font-size: 36px;
        ${media.sm} {
          font-size: 60px;
        }
        ${media.md} {
          font-size: 72px;
        }
        ${media.lg} {
          font-size: 96px;
        }
      `;
    case 'Heading1':
      return css`
        font-weight: 800;
        line-height: 1.35;
        font-size: 36px;
        ${media.sm} {
          font-size: 60px;
        }
      `;
    case 'Heading2':
      return css`
        font-weight: 700;
        line-height: 1.35;
        font-size: 32px;
        ${media.sm} {
          font-size: 44px;
        }
      `;
    case 'Heading3':
      return css`
        font-weight: 600;
        line-height: 1.5;
        font-size: 28px;
        ${media.sm} {
          font-size: 32px;
        }
      `;
    case 'Subtitle1':
      return css`
        font-weight: 500;
        line-height: 1.5;
        font-size: 24px;
        ${media.sm} {
          font-size: 28px;
        }
      `;
    case 'Subtitle2':
      return css`
        font-weight: 500;
        line-height: 1.5;
        font-size: 20px;
        ${media.sm} {
          font-size: 24px;
        }
      `;
    case 'Body1':
      return css`
        font-weight: 500;
        line-height: 1.75;
        font-size: 16px;
        ${media.sm} {
          font-size: 20px;
        }
      `;
    case 'Body2':
      return css`
        font-weight: 400;
        line-height: 1.75;
        font-size: 15px;
        ${media.sm} {
          font-size: 18px;
        }
      `;
    case 'Caption':
      return css`
        font-weight: 400;
        line-height: 1.35;
        font-size: 14px;
        ${media.sm} {
          font-size: 16px;
        }
      `;
    case 'Button':
      return css`
        font-weight: 500;
        line-height: 1.5;
        font-size: 18px;
        ${media.md} {
          font-size: 20px;
        }
      `;
    case 'Placeholder':
      return css`
        font-weight: 600;
        line-height: 1.5;
        font-size: 16px;
        ${media.sm} {
          font-size: 20px;
        }
      `;
  }
};

const CryptoDartFont = styled.span<{
  $color: ColorType | string;
  $hoverColor?: ColorType | string;
  $type: FontType;
}>`
  color: ${({ theme, $color }) => theme.colorTheme[$color] || $color};
  ${({ $type }) => generateFontStyle($type)};
`;

type FontType =
  | 'Heading0'
  | 'Heading1'
  | 'Heading2'
  | 'Heading3'
  | 'Subtitle1'
  | 'Subtitle2'
  | 'Body1'
  | 'Body2'
  | 'Caption'
  | 'Button'
  | 'Placeholder';

interface Props {
  type: FontType;
  textColor?: ColorType | string;
  hoverColor?: ColorType;
  children: ReactNode;
  className?: string;
  css?: ReturnType<typeof css>;
  as?: ElementType<any>;
}

export default memo(Font);
