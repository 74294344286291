import { Helmet } from 'react-helmet-async';
// component
import Business from 'components/about/Business';
import Greeting from 'components/about/Greeting';
import Partner from 'components/about/Partner';

const About = () => {
  return (
    <>
      <Helmet>
        <title>소개 | 크립토다트</title>
      </Helmet>
      <Greeting />
      <Business />
      <Partner />
    </>
  );
};

export default About;
