import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { css as emotionCss } from '@emotion/react';
// component
import DropDownTrigger from './DropDownTrigger';
import DropDownList from './DropDownList';
import DropDownItem from './DropDownItem';
// hook
import { DropdownContext } from 'hook/useDropdownContext';

interface Props<T> {
  value: T;
  className?: string;
  onChange: (item: T) => void | React.Dispatch<React.SetStateAction<T>>;
  css?: ReturnType<typeof emotionCss>;
}

export function Dropdown<T>({
  value,
  children,
  className,
  onChange,
  css,
}: PropsWithChildren<Props<T>>) {
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState(value);
  const firstMounded = useRef(true);

  useEffect(() => {
    if (!firstMounded.current) {
      onChange(selected as T);
    }
    firstMounded.current = false;
  }, [selected]);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };
  const handleSelctAndClose = (item: T | string) => {
    handleClose();
    setSelected(item as T);
  };

  return (
    <DropdownContext.Provider
      value={{ isOpen, selected: selected as string, handleOpen, handleClose, handleSelctAndClose }}
    >
      <div
        css={emotionCss`
          position: relative;
          height: 100%;
          ${css}
        `}
        className={className}
      >
        {children}
      </div>
    </DropdownContext.Provider>
  );
}

export default Dropdown;

Dropdown.Trigger = DropDownTrigger;
Dropdown.List = DropDownList;
Dropdown.Item = DropDownItem;
