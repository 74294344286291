import React, { HTMLAttributes, SetStateAction, useState } from 'react';
import { css } from '@emotion/react';
// component
import Icon from './Icon';
import styled from '@emotion/styled';

const Checkbox = ({ id, label, checked, ...rest }: Props) => {
  return (
    <label
      htmlFor={id}
      css={css`
        display: flex;
        align-items: center;
        gap: 12px;
        cursor: pointer;
      `}
    >
      <input
        id={id}
        type="checkbox"
        css={css`
          appearance: none;
          width: 0;
          height: 0;
        `}
        {...rest}
      />
      {checked ? (
        <Icon
          type="solid"
          size={24}
          css={css`
            letter-spacing: -1em;
          `}
        >
          Check sq-fr
        </Icon>
      ) : (
        <Icon
          type="line"
          css={css`
            letter-spacing: -1em;
          `}
        >
          Null Box
        </Icon>
      )}
      <span>{label}</span>
    </label>
  );
};

interface Props extends HTMLAttributes<HTMLInputElement> {
  id: string;
  label?: string | React.ReactNode;
  checked: boolean;
}

export default Checkbox;
